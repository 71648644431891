export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  classname?: string;
  form?: string;
  value?: string;
}

export default function ButtonPrimary(props: Props) {
  return (
    <button
      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${props.className} disabled:bg-opacity-40 print:hidden`}
      {...props}
    >
      {props.text}
    </button>
  );
}
